import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { EUROPE } from 'enums/regions';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import get from 'lodash/get';
import { toast } from 'react-toastify';
import axios from 'axios';
import { DropzoneDialog } from 'material-ui-dropzone';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { injectIntl } from 'react-intl';
import { useIntl } from 'react-intl';

const API_URL = window.env.API_URL;
const ACCESS_TOKEN = window.env.ACCESS_TOKEN;
const toastConfig = { autoClose: 3000 };

export const Project = ({ values }) => {
  const needCaseStudy = localStorage.getItem('needCase');
  const intl = useIntl();
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileName, setFileName] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [open, setOpen] = useState(false);

  // Identificador único del proyecto
  const projectId = values.data.id; // Asegúrate de que existe un ID único
  const localStorageKey = `endUserFileUrl_${projectId}`;
  const isEuropeCountry = EUROPE.includes(values.data.country);

  // Al cargar el componente, verificamos si hay un archivo subido previamente en localStorage
  useEffect(() => {
    const endUserFileUrl = localStorage.getItem(localStorageKey);
    if (endUserFileUrl) {
      setFileUploaded(true); // Si existe una URL, asumimos que ya hay un archivo subido
      setFileName(endUserFileUrl.split('/').pop()); // Extrae el nombre del archivo de la URL
    } else {
      setFileUploaded(false); // Si no hay valor, el archivo no ha sido subido
    }
  }, [localStorageKey]);

  // Función para descargar el archivo desde la URL guardada
  const handleDownloadFile = () => {
    const endUserFileUrl = localStorage.getItem(localStorageKey);
    if (endUserFileUrl) {
      window.open(endUserFileUrl, '_blank');
    } else {
      console.error('End user file URL is not available in localStorage.');
    }
  };

  // Función para subir el archivo a través de la API y guardar la URL en localStorage
  const handleSave = async (files) => {
    if (files.length > 0) {
      const file = files[0];
      const originalFileName = file.name;
      const newFileName = `end_user-${originalFileName}`;
      setFileName(originalFileName);

      const blob = new Blob([file], { type: file.type });
      const newFile = new File([blob], newFileName, { type: file.type });

      const data = new FormData();
      data.append('file', newFile);

      try {
        const url = `${API_URL}/bulk/upload`;
        const response = await axios.post(url, data, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              ACCESS_TOKEN,
            )}`,
          },
        });

        if (response.status === 200) {
          localStorage.setItem(localStorageKey, response.data.url); // Guardamos con la clave única
          setFileUploaded(true); // Actualizamos el estado para mostrar el botón de descarga
          toast.success(
            <FormattedMessage id="El archivo se envió correctamente" />,
            toastConfig,
          );
        } else {
          throw new Error('Error en la respuesta del servidor');
        }
      } catch (error) {
        console.error('Error al subir el archivo:', error);
        toast.error(
          <FormattedMessage id="Hubo un error al enviar el archivo" />,
          toastConfig,
        );
      }
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12 ViewOrEdit">
          <div>
            <strong>
              <FormattedMessage id="Nombre del proyecto:" />
            </strong>
            <span>{get(values, 'data.name', '-')}</span>
          </div>
          <div className="kt-space-20" />
          <div>
            <strong>
              <FormattedMessage id="Tipo de proyecto:" />
            </strong>
            <span>
              <FormattedMessage id={get(values, 'data.type', '-')} />
            </span>
          </div>
          <div className="kt-space-20" />

          <div className="kt-space-20" />
          <div>
            <strong>
              <FormattedMessage id="Provincia:" />
            </strong>
            <span>{get(values, 'data.state', '-')}</span>
          </div>
          <div className="kt-space-20" />
          {/* <div>
          <strong>
            <FormattedMessage id="Ciudad:" />
          </strong>
          <span>{get(values, 'data.city', '-')}</span>
        </div> */}
          <div className="kt-space-20" />
          <div>
            <strong>
              <FormattedMessage id="Provincia:" />
            </strong>
            <span>{get(values, 'data.state', '-')}</span>
          </div>
          <div className="kt-space-20" />
          {isEuropeCountry && (
            <div>
              <strong>
                <FormattedMessage id="Case of Study:" />
              </strong>
              <span>{needCaseStudy}</span>
            </div>
          )}
          <div className="kt-space-20" />
          {isEuropeCountry && (
            <div>
              {!fileUploaded && needCaseStudy === 'SI' ? (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CloudUploadIcon />}
                    onClick={() => setOpen(true)}
                  >
                    <FormattedMessage id="Subir certificado end user" />
                  </Button>
                  <DropzoneDialog
                    acceptedFiles={['application/pdf']}
                    cancelButtonText={intl.formatMessage({ id: 'Cancel' })}
                    submitButtonText={intl.formatMessage({ id: 'Send' })}
                    dialogTitle={intl.formatMessage({ id: 'Upload file' })}
                    dropzoneText={intl.formatMessage({
                      id: 'Drag and drop a file here or click',
                    })}
                    maxFileSize={5000000}
                    open={open}
                    onClose={() => setOpen(false)}
                    onSave={handleSave}
                    showPreviews={false}
                    showPreviewsInDropzone={true}
                    showFileNames={true}
                    filesLimit={1}
                  />
                </>
              ) : fileUploaded && needCaseStudy === 'SI' ? (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<GetAppIcon />}
                  onClick={handleDownloadFile}
                >
                  <FormattedMessage id="Descargar certificado end user" />
                </Button>
              ) : needCaseStudy === 'NO' ? (
                <div></div>
              ) : null}
            </div>
          )}
          {/* <div>
          <strong>
            <FormattedMessage id="Ciudad:" />
          </strong>
          <span>{get(values, 'data.city', '-')}</span>
        </div> */}
        </div>
      </div>
    </>
  );
};

export default Project;
