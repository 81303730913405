import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Portlet, PortletBody } from 'components/partials/content/Portlet';
import PorletHeader, { PorletHeaderTitle } from 'components/porletHeader';
import { getBuildingTopGlass } from 'redux/dashboard/selector';

import Button from '@material-ui/core/Button';

import Table from './table';

const BuildingFilmProject = ({ data, isLoading }) => (
  <Portlet className="kt-portlet--height-fluid">
    <PorletHeader>
      <PorletHeaderTitle>
        <FormattedMessage id="Projectos por tipo de vidrio" />
      </PorletHeaderTitle>
    </PorletHeader>
    <PortletBody>
      {!isLoading && (
        <>
          <Table data={data} />
          <div className="kt-space-20" />
        </>
      )}
    </PortletBody>
  </Portlet>
);
export default connect(getBuildingTopGlass)(BuildingFilmProject);
