/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { makeStyles } from '@material-ui/core/styles';
import { push } from 'connected-react-router';
import Dialog from './dialog';
import get from 'lodash/get';
import GetAppIcon from '@material-ui/icons/GetApp';
import { EUROPE } from 'enums/regions';

const API_URL = window.env.API_URL;

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const Actions = ({ push, value, row }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const isClient = get(row, 'data.status', '') === 'Cliente';
  const isClosed = row.isClosed;
  const isDinoc = row.subtype === 'Dinoc' || row.subtype === 'DinocSmall';
  const isFasara = row.subtype === 'Fasara' || row.subtype === 'FasaraSmall';

  const handleDownloadFile = () => {
    const endUserFileUrl = localStorage.getItem('endUserFileUrl');
    if (endUserFileUrl) {
      window.open(endUserFileUrl, '_blank');
    } else {
      console.error('End user file URL is not available in localStorage.');
    }
  };

  const isEuropeCountry = EUROPE.includes(row.data.country);
  const endUserFileExists = Boolean(localStorage.getItem('endUserFileUrl'));
  const needCase = localStorage.getItem('needCase');

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        &bull;&bull;&bull;
      </Button>

      {isClosed && isDinoc ? (
        <>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => push(`/installer/arqFinishes/dinoc/view/${value}`)}
            >
              <VisibilityIcon className={classes.icon} />
              <FormattedMessage id="Ver" />
            </MenuItem>
            {isEuropeCountry && endUserFileExists && needCase && (
              <>
                <MenuItem onClick={handleDownloadFile}>
                  <GetAppIcon className={classes.icon} />
                  <FormattedMessage id="Descargar certificado" />
                </MenuItem>
              </>
            )}
          </Menu>
        </>
      ) : isClosed && isFasara ? (
        <>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() =>
                push(`/installer/arqFinishes/fasara/view/${value}`)
              }
            >
              <VisibilityIcon className={classes.icon} />
              <FormattedMessage id="Ver" />
            </MenuItem>
            {isEuropeCountry && endUserFileExists && needCase && (
              <>
                <MenuItem onClick={handleDownloadFile}>
                  <GetAppIcon className={classes.icon} />
                  <FormattedMessage id="Descargar certificado" />
                </MenuItem>
              </>
            )}
          </Menu>
        </>
      ) : isClient && isDinoc ? (
        <>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => push(`/installer/arqFinishes/dinoc/edit/${value}`)}
            >
              <EditIcon className={classes.icon} />
              <FormattedMessage id="Editar" />
            </MenuItem>
            <MenuItem onClick={handleOpenDialog}>
              <MailOutlineIcon className={classes.icon} />
              <FormattedMessage id="Volver a enviar cert de garantía" />
            </MenuItem>
          </Menu>
          {openDialog && (
            <Dialog
              open={openDialog}
              onClose={handleCloseDialog}
              code={value}
            />
          )}
        </>
      ) : isClient && isFasara ? (
        <>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() =>
                push(`/installer/arqFinishes/fasara/edit/${value}`)
              }
            >
              <EditIcon className={classes.icon} />
              <FormattedMessage id="Editar" />
            </MenuItem>
            <MenuItem onClick={handleOpenDialog}>
              <MailOutlineIcon className={classes.icon} />
              <FormattedMessage id="Volver a enviar cert de garantía" />
            </MenuItem>
          </Menu>
          {openDialog && (
            <Dialog
              open={openDialog}
              onClose={handleCloseDialog}
              code={value}
            />
          )}
        </>
      ) : isDinoc ? (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => push(`/installer/arqFinishes/dinoc/edit/${value}`)}
          >
            <EditIcon className={classes.icon} />
            <FormattedMessage id="Editar" />
          </MenuItem>
          <MenuItem
            onClick={() => push(`/installer/arqFinishes/dinoc/win/${value}`)}
          >
            <AssignmentTurnedInIcon className={classes.icon} />
            <FormattedMessage id="Cambiar proyecto a ganado" />
          </MenuItem>
        </Menu>
      ) : (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => push(`/installer/arqFinishes/fasara/edit/${value}`)}
          >
            <EditIcon className={classes.icon} />
            <FormattedMessage id="Editar" />
          </MenuItem>
          <MenuItem
            onClick={() => push(`/installer/arqFinishes/fasara/win/${value}`)}
          >
            <AssignmentTurnedInIcon className={classes.icon} />
            <FormattedMessage id="Cambiar proyecto a ganado" />
          </MenuItem>
        </Menu>
      )}
    </>
  );
};

export default connect(undefined, { push })(Actions);
