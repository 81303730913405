import React, { useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { Chart } from 'chart.js';
import get from 'lodash/get';
import moment from 'moment';
import ctl from 'helpers/countries';

const AreaChart = ({ locale, data: values }) => {
  const ref = useRef();
  const data = useMemo(() => {
    moment.locale(locale);
    return values.reduce((acum, actual) => {
      const acumLabels = get(acum, 'labels', []);
      const newLabel = ctl(actual.country);
      const actualData = get(acum, 'datasets[0].data', []);
      const newData = actual.installers;

      return {
        ...acum,
        labels: [newLabel, ...acumLabels],
        datasets: [
          {
            backgroundColor: [
              '#36A2EB',
              '#FDCC56',
              '#FB6283',
              '#1A888A',
              '#094D8C',
              '#A2208D',
              '#06195B',
              '#4AC0C0',
              '#CACBCF',
              '#FC9F40',
              '#f58d74',
              '#bd7cb2',
              '#595959',
              '#7e6aaf',
              '#afd98f',
            ],
            data: [newData, ...actualData],
          },
        ],
      };
    }, {});
  }, [values, locale]);

  useEffect(() => {
    Chart.defaults.global.legend.labels.usePointStyle = true;
    const chart = new Chart(ref.current, {
      data,
      type: 'pie',
      options: {
        aspectRatio: 1.25,
        legend: {
          position: 'bottom',
          labels: {
            padding: 20,
            usePointStyle: true,
            generateLabels: (chart) => {
              const { datasets } = chart.data;
              return chart.data.labels.map((label, index) => {
                return {
                  text: `${label}: ${datasets[0].data[index]}`,
                  fillStyle: datasets[0].backgroundColor[index],
                  hidden: false,
                };
              });
            },
          },
        },
        tooltips: {
          enabled: false,
        },
      },
    });

    return () => {
      chart.destroy();
    };
  }, [data]);

  return <canvas ref={ref} />;
};

export const matStateToProps = (state) => ({
  locale:
    get(state, 'i18n.lang') === 'br'
      ? 'pt-br'
      : get(state, 'i18n.lang') === 'jm'
      ? 'en'
      : 'es',
});
export default connect(matStateToProps)(AreaChart);
