import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { getSendWarratyData } from 'redux/cars/editClient/selector';
import {
  actions as editClientActions,
  requests as editClientRequests,
} from 'redux/cars/editClient/reducer';

import { toast } from 'react-toastify';
import toastConfig from 'enums/toast';

const ResponsiveDialog = ({
  open,
  onClose,
  sendWarranty,
  clearData,
  isLoading,
  isSendSuccess,
  hasSendError,
  code,
}) => {
  useEffect(() => {
    if (isSendSuccess) {
      onClose();
    }
    return () => clearData();
  }, [isSendSuccess, onClose, clearData]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (!isLoading && isSendSuccess) {
      toast.success(
        <FormattedMessage id="Certificado de garantia enviado correctamente" />,
        toastConfig,
      );
    }
  }, [isLoading, isSendSuccess]);
  useEffect(() => {
    if (!isLoading && hasSendError) {
      toast.error(
        <FormattedMessage id="Se a producido un error, vuelva a intentar" />,
        toastConfig,
      );
    }
  }, [isLoading, hasSendError]);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      disableBackdropClick={isLoading}
    >
      <DialogTitle>
        <FormattedMessage id="Volver a enviar cert de garantía" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage id="¿Esta seguro que quiere volver a enviar el certificado de garantia?" />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => sendWarranty({ code })}
          color="primary"
          disabled={isLoading}
        >
          <FormattedMessage id="Si" />
        </Button>
        <Button
          variant="outlined"
          onClick={onClose}
          color="primary"
          disabled={isLoading}
        >
          <FormattedMessage id="No" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(getSendWarratyData, (dispatch) => ({
  sendWarranty: (data) => dispatch(editClientRequests.send(data)),
  clearData: () => dispatch(editClientActions.clearData()),
}))(ResponsiveDialog);
