/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { makeStyles } from '@material-ui/core/styles';
import { push } from 'connected-react-router';
import Dialog from './dialog';
import get from 'lodash/get';
import GetAppIcon from '@material-ui/icons/GetApp';
import { EUROPE } from 'enums/regions';
import { DropzoneDialog } from 'material-ui-dropzone';
import { toast } from 'react-toastify';
import toastConfig from 'enums/toast';
import axios from 'axios';
import { useIntl } from 'react-intl';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const API_URL = window.env.API_URL;
const ACCESS_TOKEN = window.env.ACCESS_TOKEN;

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const Actions = ({ push, value, row }) => {
  const classes = useStyles();
  const intl = useIntl();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState('');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleProvinceChange = (event, value) => {
    setSelectedProvince(value);
  };

  const isClient = get(row, 'data.status', '') === 'Cliente';
  const isClosed = row.isClosed;

  const handleSave = async (files) => {
    if (files.length > 0) {
      const file = files[0];
      const newFileName = `end_user-${file.name}`;
      setFileName(file.name);

      const blob = new Blob([file], { type: file.type });
      const newFile = new File([blob], newFileName, { type: file.type });

      const data = new FormData();
      data.append('file', newFile);

      try {
        const url = `${API_URL}/bulk/upload`;
        const response = await axios.post(url, data, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              ACCESS_TOKEN,
            )}`,
          },
        });

        if (response.status === 200) {
          localStorage.setItem('endUserFileUrl', response.data.url);
          setOpen(false);
          toast.success(
            <FormattedMessage id="El archivo se envió correctamente" />,
          );
        } else {
          throw new Error('Error en la respuesta del servidor');
        }
      } catch (error) {
        console.error('Error al subir el archivo:', error);
        setOpen(false);
        toast.error(
          <FormattedMessage id="Hubo un error al enviar el archivo" />,
        );
      }
    }
  };

  const handleDownloadFile = () => {
    const endUserFileUrl = localStorage.getItem('endUserFileUrl');
    if (endUserFileUrl) {
      window.open(endUserFileUrl, '_blank');
    } else {
      console.error('End user file URL is not available in localStorage.');
    }
  };

  const handleDownload = () => {
    const glassCheckFileUrl = localStorage.getItem('glassCheckFileUrl');
    if (glassCheckFileUrl) {
      window.open(glassCheckFileUrl, '_blank');
    } else {
      console.error('End user file URL is not available in localStorage.');
    }
  };

  const isEuropeCountry = EUROPE.includes(row.data.country);
  const endUserFileExists = Boolean(localStorage.getItem('endUserFileUrl'));
  const glassCheckFileExists = Boolean(
    localStorage.getItem('glassCheckFileUrl'),
  );
  const needCaseStudy = Boolean(localStorage.getItem('needCase'));
  const glassCheck = Boolean(localStorage.getItem('glassCheck'));

  const showDownloadOption = endUserFileExists && needCaseStudy == 'SI';
  const showUploadOption = !endUserFileExists && needCaseStudy == 'SI';

  const showDownloadOptions = glassCheckFileExists && glassCheck === 'SI';
  const showUploadOptions = !glassCheckFileExists && glassCheck === 'SI';

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        &bull;&bull;&bull;
      </Button>

      {isClosed ? (
        <>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => push(`/installer/building/view/${value}`)}>
              <VisibilityIcon className={classes.icon} />
              <FormattedMessage id="Ver" />
            </MenuItem>
            <MenuItem onClick={handleOpenDialog}>
              <MailOutlineIcon className={classes.icon} />
              <FormattedMessage id="Volver a enviar cert de garantía" />
            </MenuItem>
            {isEuropeCountry && (
              <>
                {showDownloadOption ? (
                  <MenuItem onClick={handleDownloadFile}>
                    <GetAppIcon className={classes.icon} />
                    <FormattedMessage id="Descargar certificado" />
                  </MenuItem>
                ) : showUploadOption ? (
                  <MenuItem>
                    <div className="w-100">
                      <div
                        onClick={() => setOpen(true)}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                          gap: '8px',
                        }}
                      >
                        <CloudDownloadIcon style={{ fontSize: 24 }} />
                        <FormattedMessage id="Upload file" />
                      </div>
                      <DropzoneDialog
                        showAlerts={false}
                        acceptedFiles={['application/pdf']}
                        cancelButtonText={intl.formatMessage({ id: 'Cancel' })}
                        submitButtonText={intl.formatMessage({ id: 'Send' })}
                        dialogTitle={intl.formatMessage({ id: 'Upload file' })}
                        dropzoneText={intl.formatMessage({
                          id: 'Drag and drop a file here or click',
                        })}
                        maxFileSize={5000000}
                        open={open}
                        onClose={() => setOpen(false)}
                        onSave={handleSave}
                        showPreviews={false}
                        showPreviewsInDropzone={true}
                        showFileNames={true}
                        filesLimit={1}
                      />
                    </div>
                  </MenuItem>
                ) : (
                  <div></div>
                )}
              </>
            )}
            {isEuropeCountry && (
              <>
                {showDownloadOptions ? (
                  <MenuItem onClick={handleDownload}>
                    <GetAppIcon className={classes.icon} />
                    <FormattedMessage id="Descargar archivo" />
                  </MenuItem>
                ) : showUploadOptions ? (
                  <MenuItem>
                    <div className="w-100">
                      <Button
                        variant="outlined"
                        name="project.file"
                        onClick={() => setOpen(true)}
                        className={`${classes.input} mt-4 w-100`}
                        style={{ width: '318px !important' }}
                      >
                        <FormattedMessage id="Upload file" />
                      </Button>
                      <DropzoneDialog
                        showAlerts={false}
                        acceptedFiles={['application/pdf']}
                        cancelButtonText={intl.formatMessage({
                          id: 'Cancel',
                        })}
                        submitButtonText={intl.formatMessage({
                          id: 'Send',
                        })}
                        dialogTitle={intl.formatMessage({
                          id: 'Upload file',
                        })}
                        dropzoneText={intl.formatMessage({
                          id: 'Drag and drop a file here or click',
                        })}
                        maxFileSize={5000000}
                        open={open}
                        onClose={() => setOpen(false)}
                        onSave={handleSave}
                        showPreviews={false}
                        showPreviewsInDropzone={true}
                        showFileNames={true}
                        filesLimit={1}
                      />
                    </div>
                  </MenuItem>
                ) : (
                  <div></div>
                )}
              </>
            )}
            {openDialog && (
              <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                code={value}
              />
            )}
          </Menu>
        </>
      ) : isClient ? (
        <>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => push(`/installer/building/edit/${value}`)}>
              <EditIcon className={classes.icon} />
              <FormattedMessage id="Editar" />
            </MenuItem>
            <MenuItem onClick={handleOpenDialog}>
              <MailOutlineIcon className={classes.icon} />
              <FormattedMessage id="Volver a enviar cert de garantía" />
            </MenuItem>
          </Menu>
          {openDialog && (
            <Dialog
              open={openDialog}
              onClose={handleCloseDialog}
              code={value}
            />
          )}
        </>
      ) : (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => push(`/installer/building/edit/${value}`)}>
            <EditIcon className={classes.icon} />
            <FormattedMessage id="Editar" />
          </MenuItem>
          <MenuItem onClick={() => push(`/installer/building/win/${value}`)}>
            <AssignmentTurnedInIcon className={classes.icon} />
            <FormattedMessage id="Cambiar proyecto a ganado" />
          </MenuItem>
        </Menu>
      )}
    </>
  );
};

export default connect(undefined, { push })(Actions);
