import React from 'react';
import { FormattedMessage } from 'react-intl';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const TableHeadTypography = withStyles({
  root: {
    fontWeight: 'bold',
  },
})(Typography);

export default ({ data }) => {
  return (
    <Table size="small" aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>
            <TableHeadTypography color="primary">
              <FormattedMessage id="País" />
            </TableHeadTypography>
          </TableCell>
          <TableCell>
            <TableHeadTypography color="primary">
              <FormattedMessage id="Descripción" />
            </TableHeadTypography>
          </TableCell>
          <TableCell align="right">
            <TableHeadTypography color="primary">
              <FormattedMessage id="M2" />
            </TableHeadTypography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, index) => {
          console.log('Row:', row);
          return (
            <TableRow key={index}>
              <TableCell>{row.country}</TableCell>
              <TableCell>{row.film}</TableCell>
              <TableCell align="right">
                <Typography color="secondary">
                  {Number(row.meters.toFixed(2)).toLocaleString()}
                </Typography>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
