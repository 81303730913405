import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import get from 'lodash/get';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  tableWrapper: {
    maxHeight: 'auto',
    overflow: 'auto',
    borderBottom: '1px solid #E0E0E0',
  },
  tableHead: {
    '& th': {
      backgroundColor: '#e6e7eb',
      fontWeight: 'bold',
      position: 'sticky',
      top: 0,
      zIndex: 2,
    },
  },
  tableBody: {
    '& tr:nth-child(even)': {
      backgroundColor: '#F4F5F9',
    },
    '& tr:nth-child(odd)': {
      backgroundColor: '#FFF',
    },
  },
});

const DataTable = ({
  intl,
  columns,
  rows,
  page,
  perPage,
  total,
  isLoading,
  hasError,
  onChangePage,
  onChangeRowsPerPage,
  filterEnabled,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead className={classes.tableHead}>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={`${index}-${get(column, 'prop')}`}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <FormattedMessage id={get(column, 'label', ' ')} />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {isLoading && (
            <TableBody className={classes.tableBody}>
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {hasError && (
            <TableBody className={classes.tableBody}>
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  <FormattedMessage id="Error" />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {!Boolean(rows.length) && !isLoading && !hasError && (
            <TableBody className={classes.tableBody}>
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  <FormattedMessage id="Sin resultados" />
                </TableCell>
              </TableRow>
            </TableBody>
          )}

          {!isLoading && !hasError && (
            <TableBody className={classes.tableBody}>
              {rows.map((row, i) => (
                <>
                  {filterEnabled ? (
                    // row.users[0].enabled ? (
                    //   <TableRow key={i}>
                    //     {columns.map((column, j) => (
                    //       <TableCell key={j} align={column.align}>
                    //         {column.format
                    //           ? column.format(
                    //               get(row, `${get(column, 'prop')}`, '-'),
                    //               row,
                    //             )
                    //           : get(row, `${get(column, 'prop')}`, '-')}
                    //       </TableCell>
                    //     ))}
                    //   </TableRow>
                    // ) : (
                    <></>
                  ) : (
                    // )
                    <TableRow key={i}>
                      {columns.map((column, j) => (
                        <TableCell key={j} align={column.align}>
                          {column.format
                            ? column.format(
                                get(row, `${get(column, 'prop')}`, '-'),
                                row,
                              )
                            : get(row, `${get(column, 'prop')}`, '-')}
                        </TableCell>
                      ))}
                    </TableRow>
                  )}
                </>
              ))}
            </TableBody>
          )}
        </Table>
      </div>
      <TablePagination
        labelRowsPerPage={intl.formatMessage({ id: 'Mostrar' })}
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={total}
        rowsPerPage={perPage}
        page={page > 0 ? page - 1 : page}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} / ${count}`
        }
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </div>
  );
};

export default injectIntl(DataTable);
